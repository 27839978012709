<template>
	<figure data-component="bridge-quote">
		<blockquote>
			<p>
				“Many games provide fun, but Bridge grips you. It exercises your mind. Your mind can rust, you know,  but Bridge prevents the rust from forming.”
			</p>
		</blockquote>
		<figcaption>
			—Omar Sharif
		</figcaption>
	</figure>
</template>

<script>

	export default {
	};

</script>

<style lang="scss" scoped>
	[data-component='bridge-quote'] {
		@include cols(10);
		@include push(1);
		text-align:center;
		blockquote {
			display:inline;
			p {
				display:inline;
				font-size:rem(32);
				font-style:italic;
				color:$c-brand-blue;
			}
		}
		figcaption {
			display:inline;
			font-size:rem(32);
			color:$c-brand-blue;
		}
	}
</style>
