<template>
	<div data-component="examples">
		<div
			data-element="example"
			v-for="(example, key) in examples"
			:key="key"
		>
			<h1
				v-html="example.heading"
			/>
			<p
				v-html="example.text"
			/>
			<btn
				:text="example.action.text"
				:route="example.action.route"
			/>
		</div>
	</div>
</template>

<script>

	import Btn from '@/components/ui/Btn';

	export default {
		components: {
			Btn
		},
		data: () => ({
			examples: [
				{
					heading: 'For students',
					text: 'Bridge 128 has eight learner levels – 1 to 8 – from absolute beginner to advanced levels. Take courses on bidding, play and defence with the bidding system of your&nbsp;choice.',
					action: {
						text: 'Sign up',
						route: '/create-account'
					}
				},
				{
					heading: 'For teachers',
					text: 'Bridge 128 is looking for the best bridge  teachers from all over the world. Upload and create content teaching your bidding system, your way, for your&nbsp;students.',
					action: {
						text: 'Become a teacher',
						route: '/about/teaching/how-to-become-a-teacher-on-bridge128'
					}
				}
			]
		})
	};

</script>

<style lang="scss" scoped>
	[data-component='examples'] {
		@include cols(12);
		@include nested-grid;
		[data-element='example'] {
			display:flex;
			flex-direction:column;
			align-items:center;
			@include mq($below: 'sm') {
				margin-bottom:rem(58);
				&:last-child {
					margin-bottom:0;
				}
			}
			@include mq('sm') {
				@include nested-cols(6, 12);
				@include nested-gut(12);
				&:nth-of-type(2) {
					margin-right:0;
				}
			}
			h1 {
				margin-bottom:rem(16);
				text-align:center;
				font-family:'Montserrat', sans-serif;
				font-size:rem(30);
				font-weight:bold;
				color:$c-brand-blue;
			}
			p {
				margin-bottom:rem(16);
				@include mq('sm') {
					margin-bottom:rem(22);
				}
				text-align:center;
				font-size:rem(22);
				color:$c-brand-blue;
			}
		}
	}
</style>
