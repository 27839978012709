<template>
	<div data-component="experience-levels-illo">
		<div
			data-element="experience-level-group"
			v-for="(group, key) in experienceLevelGroups"
			:key="key"
			:data-group="group.name"
		>
			<div
				data-element="experience-level"
				v-for="(level, levelKey) in group.levels"
				:key="levelKey"
				:data-level="level"
			>
				<div data-element="text">
					{{ level }}
				</div>
			</div>
			<p data-element="group">
				{{ group.name }}
			</p>
		</div>
	</div>
</template>

<script>

	export default {
		data: () => ({
			experienceLevelGroups: [
				{
					name: 'Beginner',
					levels: [
						1,
						2
					]
				},
				{
					name: 'Improver',
					levels: [
						3,
						4
					]
				},
				{
					name: 'Intermediate',
					levels: [
						5,
						6
					]
				},
				{
					name: 'Advanced',
					levels: [
						7,
						8
					]
				}
			]
		})
	};

</script>

<style lang="scss" scoped>
	[data-component='experience-levels-illo'] {
		@include cols(12);
		@include nested-grid;
		[data-element='experience-level-group'] {
			@include nested-grid;
			@include mq($below: 'sm') {
				@include nested-cols(6, 12);
				@include nested-gut(12);
				@include vertical-nested-gut(12);
				&:nth-of-type(2n) {
					margin-right:0;
				}
			}
			@include mq($above: 'sm') {
				@include nested-cols(3, 12);
				@include nested-gut(12);
				&:nth-of-type(4) {
					margin-right:0;
				}
			}
			[data-element='experience-level'] {
				position:relative;
				@include nested-cols(3, 6);
				@include nested-gut(6);
				border-width:3px;
				border-style:solid;
				border-radius:4px;
				user-select:none;
				@include mq($below: 'sm') {
					@include vertical-nested-gut(12);
				}
				@include mq('sm') {
					@include nested-cols(1.5, 3);
					@include nested-gut(3);
					margin-bottom:rem(12);
				}
				&:nth-of-type(2) {
					margin-right:0;
				}
				&:before {
					display:block;
					content:'';
					width:100%;
					padding-top:math.div(5,4) * 100%;
				}
				[data-element='text'] {
					position:absolute;
					top:50%; left:50%;
					transform:translate3d(-50%,-50%,0);
					font-size:rem(44);
					font-weight:700;
					color:$c-blue;
				}
				&:nth-of-type(even){
					&:after {
						content:'';
						position:absolute;
						top:3px; right:3px;
						bottom:3px; left:3px;
						display:block;
						user-select:none;
						border-width:2px;
						border-style:solid;
					}
				}
			}
			p {
				text-align:center;
				font-size:rem(22);
				font-weight:700;
				@include nested-cols(3, 3);
			}
			&[data-group='Beginner'] {
				[data-element='experience-level'] {
					border-color:$c-experience-levels-beginner-blue;
					[data-element='text'] {
						color:$c-experience-levels-beginner-blue;
					}
					&:nth-of-type(even) {
						&:after {
							border-color:$c-experience-levels-beginner-blue;
						}
					}
				}
				[data-element='group'] {
					color:$c-experience-levels-beginner-blue;
				}
			}
			&[data-group='Improver'] {
				[data-element='experience-level'] {
					border-color:$c-experience-levels-improver-green;
					[data-element='text'] {
						color:$c-experience-levels-improver-green;
					}
					&:nth-of-type(even) {
						&:after {
							border-color:$c-experience-levels-improver-green;
						}
					}
				}
				[data-element='group'] {
					color:$c-experience-levels-improver-green;
				}
			}
			&[data-group='Intermediate'] {
				[data-element='experience-level'] {
					border-color:$c-experience-levels-intermediate-red;
					[data-element='text'] {
						color:$c-experience-levels-intermediate-red;
					}
					&:nth-of-type(even) {
						&:after {
							border-color:$c-experience-levels-intermediate-red;
						}
					}
				}
				[data-element='group'] {
					color:$c-experience-levels-intermediate-red;
				}
			}
			&[data-group='Advanced'] {
				[data-element='experience-level'] {
					border-color:$c-experience-levels-advanced-purple;
					[data-element='text'] {
						color:$c-experience-levels-advanced-purple;
					}
					&:nth-of-type(even) {
						&:after {
							border-color:$c-experience-levels-advanced-purple;
						}
					}
				}
				[data-element='group'] {
					color:$c-experience-levels-advanced-purple;
				}
			}
		}
	}
</style>
