<template>
	<div data-route>
		<hero />
		<experience-levels-illo />
		<examples />
		<bridge-quote />
	</div>
</template>

<route>
	{
		"meta": {
			"isPublic": true
		}
	}
</route>

<script>

	import Hero                  from '@/components/front/Hero';
	import ExperienceLevelsIllo  from '@/components/front/ExperienceLevelsIllo';
	import Examples              from '@/components/front/Examples';
	import BridgeQuote           from '@/components/front/BridgeQuote';

	export default {
		components: {
			Hero,
			ExperienceLevelsIllo,
			Examples,
			BridgeQuote
		},
		data: () => ({
		}),
		computed: {
		},
		created () {
			this.$store.commit('ui/setLoadingIsHidden');
			this.$store.commit('ui/clearBreadcrumbs');
		}
	};

</script>

<style lang="scss" scoped>
	[data-route] {
		padding-top:rem(64);
		[data-component='hero'] {
			margin-bottom:rem(100);
			@include mq('sm') {
				margin-bottom:rem(102);
			}
		}
		[data-component='experience-levels-illo'] {
			margin-bottom:rem(64);
			@include mq('sm') {
				margin-bottom:rem(96);
			}
		}
		[data-component='examples'] {
			margin-bottom:rem(64);
			@include mq('sm') {
				margin-bottom:rem(112);
			}
		}
		[data-component='bridge-quote'] {}
	}
</style>
