<template>
	<div data-component="hero">
		<img src="@/assets/logomark-colour.svg" />
		<h1>
			The new home for learning and teaching&nbsp;bridge
		</h1>
		<p>
			Learn through online live lessons and interactive&nbsp;courses
		</p>
		<div data-element="hero-actions">
			<btn
				text="Sign up"
				route="/create-account"
				colour="red"
				size="xl"
			/>
			<btn
				text="View courses"
				route="/browse-courses"
				size="xl"
			/>
		</div>
	</div>
</template>

<script>

	import Btn from '@/components/ui/Btn';

	export default {
		components: {
			Btn
		}
	};

</script>

<style lang="scss" scoped>
	[data-component='hero'] {
		@include cols(12);
		display:flex;
		flex-direction:column;
		align-items:center;
		@include mq('sm') {
			@include cols(8);
			@include push(2);
		}
		img {
			width:rem(175);
			height:auto;
			margin:0 auto rem(32);
		}
		h1 {
			margin:0 auto rem(20);
			text-align:center;
			font-family:'Montserrat', sans-serif;
			font-size:rem(38);
			font-weight:700;
			color:$c-brand-blue;
		}
		p {
			margin:0 auto rem(32);
			text-align:center;
			font-family:'Montserrat', sans-serif;
			font-size:rem(24);
			font-weight:700;
			color:$c-brand-blue;
		}
		[data-element='hero-actions'] {
			display:flex;
			justify-content:center;
			[data-component='btn'] {
				&:first-child {
					margin-right:rem(22);
				}
			}
		}
	}
</style>
